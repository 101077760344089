@import 'styles/mixins';

.customCta {
    font-weight: 400;
    font-size: 16px;
    min-width: 120px;
    height: 48px;
    padding: 12px 16px;
    color: #FFFFFF;
    background: #101010;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-family: met_medium;

    &.secondary {
        color: #101010;
        background: #FFFFFF;
    }
}
